<template>
  <div>
    <b-row
      class="mb-2"
    >
      <b-col
        cols="auto"
      >
        <b-button
          variant="primary"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          Voltar
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-row
        align-h="between"
      >
        <b-col
          cols="6"
          class="d-flex flex-column"
        >
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  Régua: {{ info.id }}
                </h4>
              </div>
            </div>
          </div>

          <b-row>
            <b-col>
              <div class="d-flex align-items-center ml-1 mt-2">
                <b-row>
                  <b-col
                    v-for="(item, i) in cards"
                    :key="item.icon"
                    class="cursor-pointer"
                    xl="4"
                    sm="3"
                    @click="itemClick(i)"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2 mt-2"
                      >
                        <b-avatar
                          size="23"
                          :variant="item.color"
                        >
                          <feather-icon
                            size="25"
                            :icon="item.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-1">
                        <h4 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row></div>
              <b-row>
                <b-col
                  class="text-left mt-2"
                  md="3"
                >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="gradient-danger"
                    size="sm"
                    @click="pararRegua(info.id)"
                  >
                    Parar Régua
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="6"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="AtSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Tipo</span>
              </th>
              <td class="pb-50">
                {{ info.tipo }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Nome</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ info.nome }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data Inicio</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ info.created_at ? dataHora(info.created_at, true) : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50">
                {{ info.status }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemClicked === 0"
      title="Campanhas"
      class="mt-2"
    >
      <b-row
        align-h="center"
        class="mb-2"
      >
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        hover
        striped
        responsive
        sticky-header="40vh"
        :filter="filter"
        :fields="fieldsCampanhasDaRegua"
        :items="itemsCampanhasDaRegua"
        @row-clicked="setCampanha"
      >
        <template #cell(data_inicio_campanha)="data">
          {{ data.item.data_inicio_campanha ? reverseDate(data.item.data_inicio_campanha) : '' }}
        </template>
        <template #cell(primeira_execucao)="data">
          <b-badge :variant="data.item.primeira_execucao ? 'success' : 'danger'">
            {{ data.item.primeira_execucao ? 'ATIVA' : 'INATIVA' }}
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsCampanhasDaRegua.length"
        :per-page="25"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BTable,
  BFormInput,
  BButton,
  BPagination,
  BMediaAside,
  BMediaBody,
  BCardText,
  BMedia,
  // BModal,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BTable,
    BFormInput,
    BButton,
    BPagination,
    BMediaAside,
    BMediaBody,
    BCardText,
    BMedia,
    BBadge,
  },
  directives: {
    // 'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      itemClicked: 0,
      filter: '',
      colorCache: {},
      info: [],
      value: 0,
      currentPage: 1,
      cards: [],
      load: true,
      itemsCampanhasDaRegua: [],
      fieldsCampanhasDaRegua: [
        { key: 'id', label: 'Campanha ID', class: 'text-center' },
        { key: 'data_inicio_campanha', label: 'Data Inicio Campanha', class: 'text-center' },
        { key: 'primeira_execucao', label: 'STATUS', class: 'text-center' },
        { key: 'tipo_campanha', label: 'Tipo Campanha', class: 'text-center' },
        { key: 'tipo_filtro', label: 'Tipo Filtro', class: 'text-center' },
      ],
    }
  },
  created() {
    this.getInfoRegua()
    this.setCards()
  },
  methods: {
    getInfoRegua() {
      this.itemsCampanhasDaRegua = []
      this.info = []
      axios.get(`api/v1/regua_cobranca/detalhar/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.itemsCampanhasDaRegua = item.campanhas
        })
        // eslint-disable-next-line
        this.info = res.data.dados[0]
        this.setCards()
      })
    },

    randomBadge(id) {
      const badges = ['primary', 'success', '', 'danger', 'warning', 'info', 'dark']
      return badges[Math.floor(Math.random() * id)]
    },
    async setCampanha(item) {
      this.$router.push({ name: 'campanhas.view', params: { id: item.id } })
    },

    async setCards() {
      const cards = [
        {
          loadCard: true,
          color: 'light-secondary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'ActivityIcon',
          subtitle: 'Campanhas',
          title: this.info.campanhas.length,
        },
      ]
      this.cards = cards
    },

    async itemClick(data) {
      if (data === 0) {
        this.itemClicked = 0
      }
    },
    pararRegua(idRegua) {
      this.$swal({
        title: `Deseja desativar a Régua ${idRegua}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desativar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const body = {
          id: idRegua,
        }
        if (result.value) {
          axios.post('api/v1/regua_cobranca/desativar', body, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(() => {
              this.$swal({
                title: 'Campanha desativada com sucesso!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
              this.getInfoRegua()
            })
            .catch(error => {
              this.$swal({
                title: `Ops, algo deu errado: ${error.data.response.mensagem}`,
                icon: 'danger',
                timer: 1500,
                showConfirmButton: false,
              })
              this.getInfoRegua()
            })
        }
      })
    },
    reverseDate(date) {
      const dateToReverse = date.substr(0, 10)
      const dateHours = date.substr(11, 15)
      const reversedDate = dateToReverse.split('-').reverse().join('/')
      return `${reversedDate} ${dateHours}`
    },
  },
}
</script>
